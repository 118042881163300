@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";
.dashboardSidebar {
  flex: unset !important;
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
  .ant-layout-sider-children ul {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    overflow-x: auto;
    margin: 10px 0;
    li.ant-menu-item {
      padding: 15px !important;
      min-width: 180px;
      span {
        display: flex;
        justify-content: center;
      }
    }
  }
}
.dashboardSidebarOuter {
  flex-direction: column !important;
  .ant-layout-content {
    width: 100% !important;
  }
}

.fillBlack svg #Path_45869 {
  fill: transparent !important;
}
.fillWhite svg #Path_45869 {
  fill: transparent !important;
}

svg.fillBlack #Path_46078 {
  fill: $white !important;
}
svg.fillWhite #Path_46078 {
  fill: $black !important;
}
.commonPadding {
   padding: 30px;
   @media (max-width:767px) {
    padding:30px 15px;
   }
}
.overFlow{
  // overflow: auto;
  @media (min-width:991px) {
   overflow-y: auto;
   }
}