@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.adminControls {
  &_block {
    h2 {
      @include theme() {
        color: theme-get("headerDropdownTxt") !important;
      }

      @include fluid-type(320px, 1920px, 16px, 24px);
      font-weight: 600;
      margin-bottom: 20px;
    }

    button {
      max-width: 200px;
      width: 100%;
      display: block;
      margin-top: 20px;
    }
  }
}

.divider {
  @include theme() {
    border-color: theme-get("sidebarRightBorder") !important;
  }
}

.qrCode {
  @include theme() {
    border-color: theme-get("sidebarRightBorder") !important;
    background-color: theme-get("qrCodeBg") !important;
  }
}
.annoucements_block {
  h2{
    @include theme() {
      color: theme-get("titlehead");
    }
  }
  
  &_submitBtn {
    display: flex;
    justify-content: center;
    .yellowBtn {
      max-width: 200px;
    }
  }
}
