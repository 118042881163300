@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.inputLayout {
  position: relative;

  .inputCustum {
    border: 1px solid;
    border-radius: 5px;
    opacity: 1;
    width: 100%;
    min-height: 45px;
    box-shadow: none;
    position: relative !important;
    background-color: transparent;
    font-family: $baseFont !important;

    @include theme() {
      border-color: theme-get("inputborder");
    }

    @include theme() {
      color: theme-get("whiteblackBase");
    }

    &:focus {
      box-shadow: none;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      @include theme() {
        border-color: theme-get("darkWhite");
      }

      outline: none !important;
      box-shadow: none !important;
    }
  }

  input::placeholder {
    @include theme() {
      color: theme-get("inputborder1") !important;
    }

    font-family: $baseFont !important;
  }

  .inputInnerTxt {
    padding-right: 100px;
  }

  .eyeicon {

    // &:hover {
    //   @include theme() {
    //     border-color: theme-get("inputborder") !important;
    //   }
    // }
    input {
      background: transparent;

      @include theme() {
        color: theme-get("whiteblackBase");
      }
    }

    svg path {
      fill: #8d8d8d;
    }
  }

  .innerTxt {
    position: absolute;
    right: 16px;
    top: 55%;
    // transform: translateY(-50%);
    color: $CommonButton;
    cursor: pointer;
  }

  .inputInnerLargeTxt {
    padding-right: 185px;
  }

  .innerTxtImg {
    position: absolute;
    right: 16px;
    top: 55%;
    // transform: translateY(-50%);
    color: #555863;
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  .inputSearch {
    padding-left: 45px;
  }

  .searchImg {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  .label {
    letter-spacing: 0px;
    font-family: $baseFont !important;

    @include theme() {
      color: theme-get("titlehead");
    }

    opacity: 1;
    width: 100%;
    text-align: left;
    @include fluid-type(320px, 1920px, 12px, 14px);
    display: flex;
    padding-bottom: 10px;
  }

  .label1 {
    font-family: $baseFont !important;
    letter-spacing: 0px;

    @include theme() {
      color: theme-get("titlehead");
    }

    opacity: 1;
    width: 100%;
    text-align: left;
    @include fluid-type(320px, 1920px, 12px, 14px);
    display: flex;
    margin-bottom: -10px;
  }

  .textedit {
    font-weight: 450;

    @include theme() {
      color: theme-get("headerDropdownTxt");
    }
  }

  .texteditLight {
    font-weight: 450;

    @include theme() {
      color: theme-get("selectBorder");
    }
  }
}