@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.btnCustom.ant-btn {
  width: 100%;
  font-family: $baseFont;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 14px;
  @include fluid-type(320px, 1920px, 12px, 14px);
  color: $btnBaseColor;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.25s;
  border: 0px;
  box-shadow: none;

  &:hover {
    opacity: 0.9;
    color: $btnBaseColor;
  }

  &.lessPadding {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.garyBtn {
    @include theme() {
      background-color: theme-get("grayBtn");
      color: black !important;
    }
  }

  &.black {
    background-color: $black ;
    color: $btnTextLight;
  }

  &.lytgaryBtn {
    background-color: $btnTextLight;
    color: $btnBaseColor;
  }

  &.orderBtn {
    @include theme() {
      background-color: theme-get("OrderBtn");
      color: theme-get("OrderBtnColor");
    }
  }

  &.lightBtn {
    @include theme() {
      background-color: theme-get("lightBtn");
    }

    color: $btnBaseColor;
  }

  &.yellowBtn {
    background-color: $CommonButton;
    color: black;

    // @include theme() {
    //     background-color: theme-get("yellowBtn");
    //     color: $btnBaseColor;
    //   }
  }

  .leftIcon {
    // width: 18px;
    // height: 18px;
    // margin-right: 5px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .rightIcon {
    // width: 18px;
    // height: 18px;
    // margin-left: 5px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .onlyIcon {
    // width: 18px;
    // height: 18px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // margin:0 -8px;
  }

  &.medium {
    height: 29px;
  }

  &.large {
    height: 45px;
  }
}